import { showToast } from "./notify";

export const generateUrl = (src, size) => {
  //Check if source is object
  if (typeof src === "object") {
    let image_url = "";
    const { name, extension, created_at } = src;

    if (src.type === "image" && size === "-1x-1") {
      // returning original image url
      image_url = `/uploads/${name}`;
    } else if (src.type === "image") {
      if (extension === "jpg" || extension === "jpeg" || extension === "png") {
        image_url = `/image/${size}/${name}`;
      } else {
        image_url = `/uploads/${name}`;
      }
    } else {
      image_url = `/uploads/${name}`;
    }
    if (created_at) {
      image_url = image_url + "?v=" + created_at;
    }

    return process.env.REACT_APP_CDN_URL + image_url;
  }

  return src;
};

export const downloadFileFromBlob = (blog) => {
  const url = window.URL.createObjectURL(blog);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'membership-card.pkpass';
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
}

export const downloadPDFFromBase64 = (base64String, fileName, failureMessage) => {
  try {
    if (isValidURL(base64String)) {
      openInNewTab(base64String);
    } else {
      const binaryString = window.atob(base64String);
      const len = binaryString.length;
      const byteArray = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    }
  } catch (e) {
    showToast(failureMessage);
  }
};

export const openInNewTab = (target) => {
  if (isSafari()) {
    window.location.href = target;
  } else {
    const link = document.createElement('a');
    link.href = target;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const isValidURL = (str) => {
  return str.startsWith("http://") || str.startsWith("https://");
}

export const isSafari = () => {
  const userAgent = navigator.userAgent;
  return (
    userAgent.includes("Safari") && !userAgent.includes("Chrome") && !userAgent.includes("Chromium")
  );
}
