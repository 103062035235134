import React, { useEffect } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import LazyLoad from '../../components/Lazyload';
import Center from '../../components/Center';
import Section from '../../components/Section';
import { Button } from '../../components/Field';
import { Spacer, Text, Title } from '../../components/Elements';
import { trackView } from '../../helpers/analytics';

import style from './style.module.scss';

const NoMatch = ({ statusCode = "404", title = "Oops! We can’t find that page...", text = "The page you are looking for might have been removed, had its name changed or is temporaily unavailable.", onClick = () => { } }) => {
    const { navbarHeight, screenHeight } = useSelector((state) => get(state, 'storage', {}));
    const { pathname } = useLocation();
    useEffect(() => {
        trackView(pathname);
    }, [pathname]);
    return (
        <Section className={`${style.notFound}`} style={{ height: `${screenHeight - navbarHeight}px` }}>
            <Center>
                <LazyLoad className={style.wrap}>
                    <Title text={statusCode} tag="h1" fontSize='f64' animationDisabled={true} />
                    <Spacer />
                    <Title text={title} tag="h2" animationDisabled={true} />
                    <Spacer />
                    <Text text={text} animationDisabled={true} />
                    <Spacer />
                    <Button
                        label="Go to home"
                        link="/dashboard"
                        onClick={onClick}
                    />
                </LazyLoad>
            </Center>
        </Section>
    )
}

export default NoMatch;